import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import * as queryString from "query-string"
import PageTitle from "../components/PageTitle"
import FormConfirmation from "../components/FormConfirmation"
import LogoGold from "../images/logo-gold.png"
import LogoSilver from "../images/logo-silver.png"
import LogoBlue from "../images/logo-blue.png"

const LabelBeantragen = ({ location, data }) => {
  const [errorMessage, setErrorMessage] = useState({
    state: false,
    message: "",
  })
  const [goldLabel, setGoldLabel] = useState(false)
  const [labelType, setLabelType] = useState("GOLD")
  const [formConfirmDialog, setFormConfirmDialog] = useState(false)
  const [formData, setFormData] = useState(() => {
    return {
      organizationName: "",
      street: "",
      houseNo: "",
      postalCode: "",
      city: "",
      country: "",
      commercialRegisterNumber: "",
      vatIN: "",
      firstNameOfTheApplicant: "",
      lastNameOfTheApplicant: "",
      organizationFunction: "",
      applicantEmail: "",
      invoiceEmail: "",
      internetDomain: "",
      auditPartner: "",
      checkboxes: {
        authorized: false,
        consent: false,
        termsAndConditions: false,
        subscription: false,
      },
    }
  })

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      // get the label type from the query string
      const parsed = queryString.parse(location.search)
      if (parsed.label) {
        navigate("/en/label-beantragen?label=" + parsed.label)
      } else {
        navigate("/en/label-beantragen?label=GOLD")
      }
    }

    document.body.style.overflow = "visible"

    // query parameters
    const { label } = queryString.parse(location.search)
    setGoldLabel(label === "GOLD")
    setLabelType(label)
  }, [])

  function changeLabel(label) {
    if (label === "GOLD") {
      setGoldLabel(true)
      setLabelType(label)
      navigate("/label-beantragen?label=GOLD")
    }

    if (label === "SILVER") {
      setGoldLabel(false)
      setLabelType(label)
      navigate("/label-beantragen?label=SILVER")
    } else if (label === "BLUE") {
      setGoldLabel(false)
      setLabelType(label)
      navigate("/label-beantragen?label=BLUE")
    }
  }

  function modifyFormData(event) {
    const { name, value, type } = event.target

    if (type === "checkbox")
      setFormData(previousState => {
        const newData = { ...previousState }
        newData.checkboxes[name] = !previousState.checkboxes[name]
        return newData
      })
    else
      setFormData(previousState => {
        return {
          ...previousState,
          [name]: value,
        }
      })

    if (errorMessage.state === true)
      setErrorMessage({ state: false, message: "" })
  }

  function checkIfFormIsFilled() {
    if (
      formData.organizationName === "" ||
      formData.country === "" ||
      formData.street === "" ||
      formData.houseNo === "" ||
      formData.postalCode === "" ||
      formData.city === "" ||
      formData.commercialRegisterNumber === "" ||
      formData.vatIN === "" ||
      formData.firstnameOfTheApplicant === "" ||
      formData.lastnameOfTheApplicant === "" ||
      formData.organizationFunction === "" ||
      formData.applicantEmail === "" ||
      formData.invoiceEmail === "" ||
      formData.internetDomain === ""
    )
      return { success: false, invalidInput: "formElements" }

    if (
      formData.checkboxes.authorized === false ||
      formData.checkboxes.consent === false ||
      formData.checkboxes.termsAndConditions === false
    )
      return { success: false, invalidInput: "checkboxes" }

    // if (goldLabel && formData.auditPartner === "") return { "success": false, "invalidInput": "auditPartner" };
    if (labelType == "GOLD" && formData.auditPartner === "")
      return { success: false, invalidInput: "auditPartner" }

    return { success: true }
  }

  function getBackgroundColor() {
    switch (labelType) {
      case "GOLD":
        return "rgba(208, 186, 147, 0.6)"
      case "SILVER":
        return "rgb(183, 192, 204, 0.6)"
      case "BLUE":
        return "#E7F0FF"
    }
  }

  function navigateToLastPage() {
    // navigate to last page according to history that is not /label-beantragen
    const { history } = location

    if (history !== undefined) {
      let lastPage
      for (let index = 1; index < history.length; index++) {
        lastPage = history[history.length - index]

        if (lastPage.pathname !== "/label-beantragen") {
          navigate(lastPage.pathname)
          return
        }
      }
    }
    navigate("/")
  }

  async function handleFormSubmit(event) {
    event.preventDefault()
    // console.log("Gold Label: " + goldLabel);
    // console.table(formData);

    const formValidation = checkIfFormIsFilled()
    if (formValidation.success) {
      // console.log("Successful form validation");
      setFormConfirmDialog(true)
      return
    } else if (formValidation.invalidInput === "formElements")
      setErrorMessage({ state: true, message: "Bitte alle Felder ausfüllen" })
    else if (formValidation.invalidInput === "checkboxes")
      setErrorMessage({
        state: true,
        message: "Bitte aktivieren Sie alle Kontrollkästchen",
      })
    else if (formValidation.invalidInput === "auditPartner")
      setErrorMessage({
        state: true,
        message: "Bitte wählen Sie einen Audit Partner",
      })
  }

  return (
    <React.Fragment>
      <PageTitle title={"Label Beantragen"} />
      <div className={"popup page__position--full active"}>
        <div className="popup__overflow page__position--full"></div>
        <div className="popup__container">
          <a className="popup__close" onClick={() => navigateToLastPage()}></a>
          <div className="page__flex">
            {/* Left Half of the Popup */}
            <div
              className="popup__left js-popup__left"
              style={{ backgroundColor: getBackgroundColor() }}
            >
              <div className="title">
                {`Beantragung eines Cyber Trust ${process.env.GATSBY_VARIANT} Labels`}
              </div>
              <div className="popup__left--block">
                <div className="name">Beantragtes Label:</div>
                <div className="popup__tab">
                  <div className="popup__tab--head">
                    <ul>
                      <li className={labelType === "BLUE" ? "active" : ""}>
                        <a
                          className="js-popup__tab"
                          data-color="#E2EAFF"
                          data-btn="#1854A2"
                          data-rel="rel2"
                          onClick={() => changeLabel("BLUE")}
                        >
                          <div className="image">
                            <img
                              src={LogoBlue}
                              className="mw-100"
                              alt="Cyber Trust Standard Standard Label"
                              title="Cyber Trust Standard Standard Label"
                            />
                          </div>
                          <div className="info">
                            {formData.checkboxes.subscription ? '855' : '950'} EUR zzgl. Ust.
                          </div>
                        </a>
                      </li>
                      <li className={labelType === "SILVER" ? "active" : ""}>
                        <a
                          className="js-popup__tab"
                          data-color="#E2EAFF"
                          data-btn="#1854A2"
                          data-rel="rel2"
                          onClick={() => changeLabel("SILVER")}
                        >
                          <div className="image">
                            {/* TODO remove the resize after getting the good quality image */}
                            <img
                              src={LogoSilver}
                              className="mw-100"
                              style={{ width: 88 }}
                              alt="Cyber Trust Standard Silver Label"
                              title="Cyber Trust Standard Silver Label"
                            />
                          </div>
                          <div className="info">
                            {formData.checkboxes.subscription ? '1700' : '1890'} EUR zzgl. Ust.
                          </div>
                        </a>
                      </li>
                      <li className={labelType === "GOLD" ? "active" : ""}>
                        <a
                          className="js-popup__tab"
                          data-color="#FFEBD1"
                          data-btn="#C89E67"
                          data-rel="rel1"
                          onClick={() => changeLabel("GOLD")}
                        >
                          <div className="image">
                            <img
                              src={LogoGold}
                              className="mw-100"
                              alt="Cyber Trust Standard Gold Label"
                              title="Cyber Trust Standard Gold Label"
                            />
                          </div>
                          <div className="info">
                            {formData.checkboxes.subscription ? '1790' : '1990'} EUR zzgl. Ust.*
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="popup__tab--body js-tab_body">
                    <div
                      id="rell"
                      className={`popup__tab--item${goldLabel ? " active" : ""}`}
                    >
                      <div className="popup__tab--top">
                        Gewünschter{" "}
                        <a onClick={() => navigate("/partner")}>
                          Audit-Partner
                        </a>
                      </div>
                      <div className="popup__tab--select">
                        <select
                          value={formData.auditPartner}
                          name="auditPartner"
                          onChange={modifyFormData}
                        >
                          <option key={-1} value=""></option>
                          {data.allDatoCmsPartnerBlock.edges[0].node.partners.map(
                            (partner, index) => (
                              <option key={index} value={partner.name}>
                                {partner.name}
                              </option>
                            )
                          )}
                          <option value="keine Präferenz">
                            Keine Präferenz
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="popup__tab--checkbox" style={{ marginTop: "30px", marginBottom: "20px" }}>
                      <div style={{ marginBottom: "8px" }}><strong>Abo Modell:</strong></div>
                      <div className="checked">
                        <label>
                          <input
                            type="checkbox"
                            name="subscription"
                            checked={formData.checkboxes.subscription}
                            onChange={modifyFormData}
                          />
                          <span></span>
                          Ich möchte das Label für drei Jahre abonnieren und dabei 10% auf die Labelgebühren sparen
                        </label>
                      </div>
                    </div>

                    <div
                      className={`popup__tab--desc--${labelType.toLowerCase()}`}
                    >
                      <sup>*</sup>
                      Auditgebühren werden separat verrechnet
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Half of the Popup */}
            <div className="popup__right">
              <div className="popup__right--inner">
                <form onSubmit={handleFormSubmit} className="form__popup">
                  <div className="form__popup--title">
                    Angaben zur Organisation
                  </div>
                  <div className="page__flex">
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="organizationName"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.organizationName}
                        />
                        <label
                          style={{
                            display:
                              formData.organizationName === "" ? "" : "none",
                          }}
                        >
                          Name der Organisation
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <select
                          name="country"
                          className="js-used"
                          value={formData.country}
                          onChange={modifyFormData}
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            height: "40px",
                            padding: "5px 10px",
                            outline: "none",
                            border: "none",
                            fontSize: "0.875em",
                            color: "#6a6a6a",
                          }}
                        >
                          <option value=""></option>
                          <option value="AT">Österreich</option>
                          <option value="DE">Deutschland</option>
                          <option value="CH">Schweiz</option>
                        </select>
                        <label
                          style={{
                            display: formData.country === "" ? "" : "none",
                          }}
                        >
                          Land
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="street"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.street}
                        />
                        <label
                          style={{
                            display: formData.street === "" ? "" : "none",
                          }}
                        >
                          Straße
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="houseNo"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.houseNo}
                        />
                        <label
                          style={{
                            display: formData.houseNo === "" ? "" : "none",
                          }}
                        >
                          Hausnummer
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="postalCode"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.postalCode}
                        />
                        <label
                          style={{
                            display: formData.postalCode === "" ? "" : "none",
                          }}
                        >
                          Postleitzahl
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="city"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.city}
                        />
                        <label
                          style={{
                            display: formData.city === "" ? "" : "none",
                          }}
                        >
                          Stadt
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="internetDomain"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.internetDomain}
                        />
                        <label
                          style={{
                            display:
                              formData.internetDomain === "" ? "" : "none",
                          }}
                        >
                          Internet-Domäne der Organisation
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="invoiceEmail"
                          type="email"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.invoiceEmail}
                        />
                        <label
                          style={{
                            display: formData.invoiceEmail === "" ? "" : "none",
                          }}
                        >
                          Rechnungs E-Mail Adresse
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="commercialRegisterNumber"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.commercialRegisterNumber}
                        />
                        <label
                          style={{
                            display:
                              formData.commercialRegisterNumber === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Firmenbuchnummer
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="vatIN"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.vatIN}
                        />
                        <label
                          style={{
                            display: formData.vatIN === "" ? "" : "none",
                          }}
                        >
                          UID-Nummer
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="firstNameOfTheApplicant"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.firstNameOfTheApplicant}
                        />
                        <label
                          style={{
                            display:
                              formData.firstNameOfTheApplicant === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Vorname des Antragstellers
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="lastNameOfTheApplicant"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.lastNameOfTheApplicant}
                        />
                        <label
                          style={{
                            display:
                              formData.lastNameOfTheApplicant === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Nachname des Antragstellers
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="organizationFunction"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.organizationFunction}
                        />
                        <label
                          style={{
                            display:
                              formData.organizationFunction === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Funktion in der Organisation
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="applicantEmail"
                          type="email"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.applicantEmail}
                        />
                        <label
                          style={{
                            display: formData.applicantEmail === "" ? "" : "none",
                          }}
                        >
                          Antragsteller E-Mail Adresse
                        </label>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="form__popup--check">
                    <div className="checked">
                      <label htmlFor="ch1">
                        <input
                          type="checkbox"
                          name="authorized"
                          id="ch1"
                          checked={formData.checkboxes.authorized}
                          onChange={modifyFormData}
                        />
                        <span></span>
                        Ich bestätige, dass ich autorisiert bin, für oben
                        genanntes Unternehmen ein Cyber Trust Label zu
                        beantragen
                      </label>
                    </div>
                    <div className="checked">
                      <label htmlFor="ch2">
                        <input
                          type="checkbox"
                          name="consent"
                          id="ch2"
                          checked={formData.checkboxes.consent}
                          onChange={modifyFormData}
                        />
                        <span></span>
                        Ich stimme der Bearbeitung meiner Kontaktdaten im Rahmen
                        der Erstellung des Cyber Trust Labels zu{" "}
                        <div className="inline nowrap">
                          (
                          <a
                            href="/datenschutzerklaerung"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Datenschutzerklärung
                          </a>
                          )
                        </div>
                      </label>
                    </div>
                    <div className="checked">
                      <label htmlFor="ch3">
                        <input
                          type="checkbox"
                          name="termsAndConditions"
                          id="ch3"
                          checked={formData.checkboxes.termsAndConditions}
                          onChange={modifyFormData}
                        />
                        <span></span>
                        Ich stimme den Allgemeinen Bedingungen des
                        Labelnutzungsvertrages zu (
                        <a href="/label_agb.pdf" target="_blank">
                          AGB
                        </a>
                        )
                      </label>
                    </div>
                  </div>
                  <button className={"page__btn page__btn--blue"} type="submit">
                    Label beantragen
                  </button>
                  <p
                    className="popup__error-indicator"
                    style={{ display: `${errorMessage.state ? "" : "none"}` }}
                  >
                    {errorMessage.message}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormConfirmation
        visible={formConfirmDialog}
        formData={formData}
        goldLabel={goldLabel}
        labelType={labelType}
        closeDialog={() => setFormConfirmDialog(false)}
      />
    </React.Fragment>
  )
}

export default LabelBeantragen

export const query = graphql`
  query {
    allDatoCmsPartnerBlock(limit: 1, filter: { partnerType: { eq: "Test" } }) {
      edges {
        node {
          partners {
            name
          }
          partnerType
        }
      }
    }
  }
`
