import React from "react"
import { APPLICATION_LABEL_API } from "../config/keys"
import { navigate } from "gatsby"

const FormConfirmation = ({
  visible,
  formData,
  goldLabel,
  labelType,
  closeDialog,
}) => {
  function getCountry(countryCode) {
    if (countryCode === "AT") return "Österreich"
    else if (countryCode === "DE") return "Deutschland"
    else if (countryCode === "CH") return "Schweiz"
    else return ""
  }

  async function processPayments(formData) {
    const formBody = {
      language: process.env.GATSBY_VARIANT === "Austria" ? "DE" : "EN",
      variant: process.env.GATSBY_VARIANT,
      organization: {
        name: formData.organizationName,
        function: formData.organizationFunction,
        commercialRegisterNumber: formData.commercialRegisterNumber,
        vatIN: formData.vatIN,
      },
      address: {
        street: formData.street,
        houseNo: formData.houseNo,
        city: formData.city,
        country: {
          name: getCountry(formData.country),
          code: formData.country,
        },
        postalCode: formData.postalCode,
      },
      firstName: formData.firstNameOfTheApplicant,
      lastName: formData.lastNameOfTheApplicant,
      applicantEmail: formData.applicantEmail,
      invoiceEmail: formData.invoiceEmail,
      websiteDomain: formData.internetDomain,
      goldLabel: goldLabel,
      labelType: labelType,
      auditPartner: formData.auditPartner,
      subscription: formData.checkboxes.subscription,
    }

    await fetch(APPLICATION_LABEL_API, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formBody),
    })

    closeDialog()
    navigate("/payments")
  }

  function getTitle() {
    switch (labelType) {
      case "GOLD":
        return "Cyber Trust Gold Label"
      case "SILVER":
        return "Cyber Trust Silber Label"
      case "BLUE":
        return "Cyber Trust Label"
      default:
        return ""
    }
  }

  return (
    <div
      className="form-confirmation__container"
      style={{ display: visible ? "" : "none" }}
    >
      <div className="form-confirmation__dialog">
        <h2>Bitte überprüfen Sie alle Angaben zu Ihrer Bestellung</h2>
        <div className="form-details">
          <h4>Beantragtes Label</h4>
          {formData.checkboxes.subscription && (
            <p>Abo auf drei Jahre</p>
          )}
          <p>{getTitle()}</p>

          <div className={labelType == "GOLD" ? "" : "hidden"}>
            <h4>Audit-Partner</h4>
            <p>{formData.auditPartner}</p>
          </div>

          <h4>Kosten</h4>
          <p>
            Netto{" "}
            {formData.checkboxes.subscription
              ? labelType == "GOLD"
                ? "5370"
                : labelType == "BLUE"
                  ? "2565"
                  : "5100"
              : labelType == "GOLD"
                ? "1990"
                : labelType == "BLUE"
                  ? "950"
                  : "1890"}{" "}
            EUR
            <br />
            zzgl. 20% USt.{" "}
            {formData.checkboxes.subscription
              ? labelType == "GOLD"
                ? "1074"
                : labelType == "BLUE"
                  ? "513"
                  : "1020"
              : labelType == "GOLD"
                ? "398"
                : labelType == "BLUE"
                  ? "190"
                  : "378"}{" "}
            EUR
            <br />
            Zahlbetrag gesamt{" "}
            {formData.checkboxes.subscription
              ? labelType == "GOLD"
                ? "6444"
                : labelType == "BLUE"
                  ? "3078"
                  : "6120"
              : labelType == "GOLD"
                ? "2388"
                : labelType == "BLUE"
                  ? "1140"
                  : "2268"}{" "}
            EUR
          </p>

          <h4>Angaben zum Besteller</h4>
          <p>
            Name: {formData.organizationName}
            <br />
            Straße: {formData.street}
            <br />
            Hausnummer: {formData.houseNo}
            <br />
            PLZ: {formData.postalCode}
            <br />
            Stadt: {formData.city}
            <br />
            Land: {formData.country}
            <br />
            Firmenbuchnummer: {formData.commercialRegisterNumber}
            <br />
            UID-Nummer: {formData.vatIN}
            <br />
            Internet-Domäne: {formData.internetDomain}
          </p>

          <h4>Ansprechpartner</h4>
          <p>
            Vorname: {formData.firstNameOfTheApplicant}
            <br />
            Nachname: {formData.lastNameOfTheApplicant}
            <br />
            Funktion: {formData.organizationFunction}
            <br />
            Rechnungs E-Mail: {formData.invoiceEmail}
            <br />
            Antragsteller E-Mail: {formData.applicantEmail}
          </p>
        </div>
        <div className="form-confirmation__buttons">
          <button
            className="page__btn form-confirmation__back-button"
            onClick={closeDialog}
          >
            Zurück
          </button>
          &nbsp;
          <button
            className="page__btn page__btn--blue form-confirmation__confirm-button"
            onClick={() => processPayments(formData)}
          >
            Zahlungspflichtig bestellen
          </button>
        </div>
      </div>
    </div>
  )
}

export default FormConfirmation
