import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import * as queryString from "query-string"
import PageTitle from "../components/PageTitle"
import FormConfirmation from "../components/FormConfirmation.en"
import LogoGold from "../images/logo-gold.png"
import LogoSilver from "../images/logo-silver.png"
import LogoBlue from "../images/logo-blue.png"
import LogoBlueEU from "../images/europe/logo.png"
import LogoSilverEU from "../images/europe/download-logo-silver.png"
import LogoGoldEU from "../images/europe/download-logo-gold.png"

const LabelBeantragen = ({ location, data }) => {
  const [errorMessage, setErrorMessage] = useState({
    state: false,
    message: "",
  })
  const [goldLabel, setGoldLabel] = useState(false)
  const [labelType, setLabelType] = useState("GOLD")
  const [formConfirmDialog, setFormConfirmDialog] = useState(false)
  const [formData, setFormData] = useState(() => {
    return {
      organizationName: "",
      street: "",
      houseNo: "",
      postalCode: "",
      city: "",
      country: "",
      commercialRegisterNumber: "",
      vatIN: "",
      firstNameOfTheApplicant: "",
      lastNameOfTheApplicant: "",
      organizationFunction: "",
      applicantEmail: "",
      invoiceEmail: "",
      internetDomain: "",
      auditPartner: "",
      checkboxes: {
        authorized: false,
        consent: false,
        termsAndConditions: false,
        subscription: false,
      },
    }
  })

  useEffect(() => {
    document.body.style.overflow = "visible"

    // query parameters
    const { label } = queryString.parse(location.search)
    setGoldLabel(label === "GOLD")
    setLabelType(label)
  }, [])

  function changeLabel(label) {
    if (label === "GOLD") {
      setGoldLabel(true)
      setLabelType(label)
      navigate("/en/label-beantragen?label=GOLD")
    }

    if (label === "SILVER") {
      setGoldLabel(false)
      setLabelType(label)
      navigate("/en/label-beantragen?label=SILVER")
    } else if (label === "BLUE") {
      setGoldLabel(false)
      setLabelType(label)
      navigate("/en/label-beantragen?label=BLUE")
    }
  }

  function modifyFormData(event) {
    const { name, value, type } = event.target

    if (type === "checkbox")
      setFormData(previousState => {
        const newData = { ...previousState }
        newData.checkboxes[name] = !previousState.checkboxes[name]
        return newData
      })
    else
      setFormData(previousState => {
        return {
          ...previousState,
          [name]: value,
        }
      })

    if (errorMessage.state === true)
      setErrorMessage({ state: false, message: "" })
  }

  function checkIfFormIsFilled() {
    if (
      formData.organizationName === "" ||
      formData.country === "" ||
      formData.street === "" ||
      formData.houseNo === "" ||
      formData.postalCode === "" ||
      formData.city === "" ||
      formData.commercialRegisterNumber === "" ||
      formData.vatIN === "" ||
      formData.firstnameOfTheApplicant === "" ||
      formData.lastnameOfTheApplicant === "" ||
      formData.organizationFunction === "" ||
      formData.applicantEmail === "" ||
      formData.invoiceEmail === "" ||
      formData.internetDomain === ""
    )
      return { success: false, invalidInput: "formElements" }

    if (
      formData.checkboxes.authorized === false ||
      formData.checkboxes.consent === false ||
      formData.checkboxes.termsAndConditions === false
    )
      return { success: false, invalidInput: "checkboxes" }

    if (labelType == "GOLD" && formData.auditPartner === "")
      return { success: false, invalidInput: "auditPartner" }

    return { success: true }
  }

  function getBackgroundColor() {
    switch (labelType) {
      case "GOLD":
        return "rgba(208, 186, 147, 0.6)"
      case "SILVER":
        return "rgb(183, 192, 204, 0.6)"
      case "BLUE":
        return "#E7F0FF"
    }
  }

  function navigateToLastPage() {
    // navigate to last page according to history that is not /label-beantragen
    const { history } = location

    if (history !== undefined) {
      let lastPage
      for (let index = 1; index < history.length; index++) {
        lastPage = history[history.length - index]

        if (lastPage.pathname !== "/label-beantragen") {
          navigate(lastPage.pathname)
          return
        }
      }
    }

    navigate("/en/")
  }

  async function handleFormSubmit(event) {
    event.preventDefault()
    // console.log("Gold Label: " + goldLabel);
    // console.table(formData);

    const formValidation = checkIfFormIsFilled()
    if (formValidation.success) {
      // console.log("Successful form validation");
      setFormConfirmDialog(true)
      return
    } else if (formValidation.invalidInput === "formElements")
      setErrorMessage({ state: true, message: "Please fill in all fields" })
    else if (formValidation.invalidInput === "checkboxes")
      setErrorMessage({
        state: true,
        message: "Please activate all check boxes",
      })
    else if (formValidation.invalidInput === "auditPartner")
      setErrorMessage({
        state: true,
        message: "Please choose an audit partner",
      })
  }

  return (
    <React.Fragment>
      <PageTitle title={"Request Label"} />
      <div className={"popup page__position--full active"}>
        <div className="popup__overflow page__position--full"></div>
        <div className="popup__container">
          <a className="popup__close" onClick={() => navigateToLastPage()}></a>
          <div className="page__flex">
            {/* Left Half of the Popup */}
            <div
              className="popup__left js-popup__left"
              style={{ backgroundColor: getBackgroundColor() }}
            >
              <div className="title">
                {`Request for a Cyber Trust ${process.env.GATSBY_VARIANT} Label`}
              </div>
              <div className="popup__left--block">
                <div className="name">Requested Label:</div>
                <div className="popup__tab">
                  <div className="popup__tab--head">
                    <ul>
                      <li className={labelType === "BLUE" ? "active" : ""}>
                        <a
                          className="js-popup__tab"
                          data-color="#E2EAFF"
                          data-btn="#1854A2"
                          data-rel="rel2"
                          onClick={() => changeLabel("BLUE")}
                        >
                          <div className="image">
                            <img
                              src={
                                process.env.GATSBY_VARIANT === "Austria"
                                  ? LogoBlue
                                  : LogoBlueEU
                              }
                              className="mw-100"
                              alt="Cyber Trust Austria Standard Label"
                              title="Cyber Trust Austria Standard Label"
                            />
                          </div>
                          <div className="info">
                            {formData.checkboxes.subscription ? '855' : '950'} EUR plus Tax
                          </div>
                        </a>
                      </li>
                      <li className={labelType === "SILVER" ? "active" : ""}>
                        <a
                          className="js-popup__tab"
                          data-color="#E2EAFF"
                          data-btn="#1854A2"
                          data-rel="rel2"
                          onClick={() => changeLabel("SILVER")}
                        >
                          <div className="image">
                            {/* TODO remove the resize after getting the good quality image */}
                            <img
                              src={
                                process.env.GATSBY_VARIANT === "Austria"
                                  ? LogoSilver
                                  : LogoSilverEU
                              }
                              className="mw-100"
                              style={{ width: 88 }}
                              alt="Cyber Trust Austria Silver Label"
                              title="Cyber Trust Austria Silver Label"
                            />
                          </div>
                          <div className="info">
                            {formData.checkboxes.subscription ? '1700' : '1890'} EUR plus Tax
                          </div>
                        </a>
                      </li>
                      <li className={labelType === "GOLD" ? "active" : ""}>
                        <a
                          className="js-popup__tab"
                          data-color="#FFEBD1"
                          data-btn="#C89E67"
                          data-rel="rel1"
                          onClick={() => changeLabel("GOLD")}
                        >
                          <div className="image">
                            <img
                              src={
                                process.env.GATSBY_VARIANT === "Austria"
                                  ? LogoGold
                                  : LogoGoldEU
                              }
                              className="mw-100"
                              alt="Cyber Trust Austria Gold Label"
                              title="Cyber Trust Austria Gold Label"
                            />
                          </div>
                          <div className="info">
                            {formData.checkboxes.subscription ? '1790' : '1990'} EUR plus Tax*
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="popup__tab--body js-tab_body">
                    <div
                      id="rell"
                      className={`popup__tab--item${goldLabel ? " active" : ""}`}
                    >
                      <div className="popup__tab--top">
                        Selected{" "}
                        <a onClick={() => navigate("/en/partner")}>
                          Audit partner
                        </a>
                      </div>
                      <div className="popup__tab--select">
                        <select
                          value={formData.auditPartner}
                          name="auditPartner"
                          onChange={modifyFormData}
                        >
                          <option key={-1} value=""></option>
                          {data.allDatoCmsPartnerBlock.edges[0].node.partners.map(
                            (partner, index) => (
                              <option key={index} value={partner.name}>
                                {partner.name}
                              </option>
                            )
                          )}
                          <option value="No preference">No preference</option>
                        </select>
                      </div>
                    </div>

                    <div className="popup__tab--checkbox" style={{ marginTop: "30px", marginBottom: "20px" }}>
                      <div style={{ marginBottom: "8px" }}><strong>Subscription:</strong></div>
                      <div className="checked">
                        <label>
                          <input
                            type="checkbox"
                            name="subscription"
                            checked={formData.checkboxes.subscription}
                            onChange={modifyFormData}
                          />
                          <span></span>
                          I want to subscribe to the label for three years and save 10% on the label fees
                        </label>
                      </div>
                    </div>

                    <div
                      className={`popup__tab--desc--${labelType.toLowerCase()}`}
                    >
                      <sup>*</sup>
                      Audit fees are charged separately
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Half of the Popup */}
            <div className="popup__right">
              <div className="popup__right--inner">
                <form onSubmit={handleFormSubmit} className="form__popup">
                  <div className="form__popup--title">
                    Details about the organisation:
                  </div>
                  <div className="page__flex">
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="organizationName"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.organizationName}
                        />
                        <label
                          style={{
                            display:
                              formData.organizationName === "" ? "" : "none",
                          }}
                        >
                          Name of the organisation
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <select
                          name="country"
                          className="js-used"
                          value={formData.country}
                          onChange={modifyFormData}
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            height: "40px",
                            padding: "5px 10px",
                            outline: "none",
                            border: "none",
                            fontSize: "0.875em",
                            color: "#6a6a6a",
                          }}
                        >
                          <option value=""></option>
                          <option value="AF">Afghanistan</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">Bolivia</option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">
                            British Indian Ocean Territory
                          </option>
                          <option value="VG">British Virgin Islands</option>
                          <option value="BN">Brunei</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curacao</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="CD">
                            Democratic Republic of the Congo
                          </option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="TL">East Timor</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">Falkland Islands</option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FT">France</option>
                          <option value="PF">French Polynesia</option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">Iran</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="CI">Ivory Coast</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="XK">Kosovo</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">Laos</option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macau</option>
                          <option value="MK">Macedonia</option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">Micronesia</option>
                          <option value="MD">Moldova</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="AN">Netherlands Antilles</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="KP">North Korea</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">Palestine</option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="CG">Republic of the Congo</option>
                          <option value="RE">Reunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russia</option>
                          <option value="RW">Rwanda</option>
                          <option value="BL">Saint Barthelemy</option>
                          <option value="SH">Saint Helena</option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="MF">Saint Martin</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SX">Sint Maarten</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="KR">South Korea</option>
                          <option value="SS">South Sudan</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">Syria</option>
                          <option value="TW">Taiwan</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">Tanzania</option>
                          <option value="TH">Thailand</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="VI">U.S. Virgin Islands</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VA">Vatican</option>
                          <option value="VE">Venezuela</option>
                          <option value="VN">Vietnam</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </select>
                        <label
                          style={{
                            display: formData.country === "" ? "" : "none",
                          }}
                        >
                          Country
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="street"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.street}
                        />
                        <label
                          style={{
                            display: formData.street === "" ? "" : "none",
                          }}
                        >
                          Street
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="houseNo"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.houseNo}
                        />
                        <label
                          style={{
                            display: formData.houseNo === "" ? "" : "none",
                          }}
                        >
                          Number
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="postalCode"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.postalCode}
                        />
                        <label
                          style={{
                            display: formData.postalCode === "" ? "" : "none",
                          }}
                        >
                          ZIP Code
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="city"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.city}
                        />
                        <label
                          style={{
                            display: formData.city === "" ? "" : "none",
                          }}
                        >
                          City
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="internetDomain"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.internetDomain}
                        />
                        <label
                          style={{
                            display:
                              formData.internetDomain === "" ? "" : "none",
                          }}
                        >
                          Qualified internet domain of the organisation
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="invoiceEmail"
                          type="email"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.invoiceEmail}
                        />
                        <label
                          style={{
                            display: formData.invoiceEmail === "" ? "" : "none",
                          }}
                        >
                          Invoice E-Mail address
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="commercialRegisterNumber"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.commercialRegisterNumber}
                        />
                        <label
                          style={{
                            display:
                              formData.commercialRegisterNumber === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Commercial register number
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="vatIN"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.vatIN}
                        />
                        <label
                          style={{
                            display: formData.vatIN === "" ? "" : "none",
                          }}
                        >
                          UID / VAT Number
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="firstNameOfTheApplicant"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.firstNameOfTheApplicant}
                        />
                        <label
                          style={{
                            display:
                              formData.firstNameOfTheApplicant === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Applicant's first name
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="lastNameOfTheApplicant"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.lastNameOfTheApplicant}
                        />
                        <label
                          style={{
                            display:
                              formData.lastNameOfTheApplicant === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Surname of the applicant
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="organizationFunction"
                          type="text"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.organizationFunction}
                        />
                        <label
                          style={{
                            display:
                              formData.organizationFunction === ""
                                ? ""
                                : "none",
                          }}
                        >
                          Function in the organization
                        </label>
                        <span></span>
                      </div>
                    </div>
                    <div className="form__popup--group">
                      <div className="form__group">
                        <input
                          name="applicantEmail"
                          type="email"
                          className="js-used"
                          onChange={modifyFormData}
                          value={formData.applicantEmail}
                        />
                        <label
                          style={{
                            display: formData.applicantEmail === "" ? "" : "none",
                          }}
                        >
                          Applicant E-Mail address
                        </label>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="form__popup--check">
                    <div className="checked">
                      <label htmlFor="ch1">
                        <input
                          type="checkbox"
                          name="authorized"
                          id="ch1"
                          checked={formData.checkboxes.authorized}
                          onChange={modifyFormData}
                        />
                        <span></span>I confirm that I am authorized to apply for
                        a Cyber Trust Label for the company named above
                      </label>
                    </div>
                    <div className="checked">
                      <label htmlFor="ch2">
                        <input
                          type="checkbox"
                          name="consent"
                          id="ch2"
                          checked={formData.checkboxes.consent}
                          onChange={modifyFormData}
                        />
                        <span></span>I consent to the processing of my contact
                        details as part of the creation of the Cyber Trust Label{" "}
                        <div className="inline nowrap">
                          (
                          <a
                            href="/en/data-protection-declaration"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            data protection declaration
                          </a>
                          )
                        </div>
                      </label>
                    </div>
                    <div className="checked">
                      <label htmlFor="ch3">
                        <input
                          type="checkbox"
                          name="termsAndConditions"
                          id="ch3"
                          checked={formData.checkboxes.termsAndConditions}
                          onChange={modifyFormData}
                        />
                        <span></span>I agree to the general terms and conditions
                        of the label usage contract (
                        <a href="/label_gtc.pdf" target="_blank">
                          GTC
                        </a>
                        )
                      </label>
                    </div>
                  </div>
                  <button className={"page__btn page__btn--blue"} type="submit">
                    Request Label
                  </button>
                  <p
                    className="popup__error-indicator"
                    style={{ display: `${errorMessage.state ? "" : "none"}` }}
                  >
                    {errorMessage.message}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormConfirmation
        visible={formConfirmDialog}
        formData={formData}
        goldLabel={goldLabel}
        labelType={labelType}
        closeDialog={() => setFormConfirmDialog(false)}
      />
    </React.Fragment>
  )
}

export default LabelBeantragen

export const query = graphql`
  query {
    allDatoCmsPartnerBlock(limit: 1, filter: { partnerType: { eq: "Test" } }) {
      edges {
        node {
          partners {
            name
          }
          partnerType
        }
      }
    }
  }
`
