import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

// Images
import BlueLabel from "../images/logo-blue-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"
import BlueLabelEU from "../images/europe/logo.svg"
import GoldLabelEU from "../images/europe/download-logo-gold.svg"
import SilverLabelEU from "../images/europe/download-logo-silver.svg"

const PriceList = () => {
  return (
    <React.Fragment>
      <PageTitle title={"Pricing"} />
      <Layout>
        <div className="pricelist-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/en")}>Home</a>
              </li>
              <li>
                <a>Pricing</a>
              </li>
            </ul>
          </div>

          <div className="pricelist">
            <div className="contact__box" style={{ height: 345 }}>
              <h1>Pricing</h1>
              <div className="contact-horizontal-line"></div>
              <div className="contact__box--block">
                <p>
                  There are three quality levels of the label in order to be
                  able to cover different target groups. The quality levels
                  differ in terms of their security claim (Security Claim) and
                  the degree of assurance of the review (Assurance Level).
                </p>
              </div>
            </div>

            <div className="pricelist__table">
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell"></div>
                <div className="pricelist__table--cell">
                  <img
                    className="mw-100"
                    src={
                      process.env.GATSBY_VARIANT === "Austria"
                        ? BlueLabel
                        : BlueLabelEU
                    }
                    alt="Cyber Trust Austria Standard Label"
                    title="Cyber Trust Austria Standard Label"
                  />
                </div>
                <div className="pricelist__table--cell">
                  <img
                    className="mw-100"
                    src={
                      process.env.GATSBY_VARIANT === "Austria"
                        ? SilverLabel
                        : SilverLabelEU
                    }
                    alt="Cyber Trust Austria Silver Label"
                    title="Cyber Trust Austria Silver Label"
                  />
                </div>
                <div className="pricelist__table--cell">
                  <img
                    className="mw-100"
                    src={
                      process.env.GATSBY_VARIANT === "Austria"
                        ? GoldLabel
                        : GoldLabelEU
                    }
                    alt="Cyber Trust Austria Gold Label"
                    title="Cyber Trust Austria Gold Label"
                  />
                </div>
              </div>

              <div className="pricelist__table--row">
                <div className="pricelist__table--cell"></div>
                <div className="pricelist__table--cell">Label</div>
                <div className="pricelist__table--cell">Label Silver</div>
                <div className="pricelist__table--cell">Label Gold</div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell"></div>
                <div
                  className="pricelist__table--cell"
                  style={{ padding: "15px 20px 15px 20px" }}
                >
                  The basic label addresses primarily smaller companies and
                  organisations. It comprises 14 baseline security requirements,
                  which any organisation should be able to fulfil; the effort is
                  reasonably limited. The assessment is based on a validated
                  self-declaration. The process is therefore simple and fast.
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{ padding: "15px 20px 15px 20px" }}
                >
                  The advanced silver label is aimed at companies and
                  organizations that need or want to meet a higher level of
                  security. It includes the 14 basic safety criteria plus 11
                  additional criteria. Fulfilling the requirements requires
                  appropriate preparation and a certain amount of effort. The
                  assessment is based on a validated self-declaration. The
                  process is therefore relatively simple and can be carried out
                  quickly.
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{ padding: "15px 20px 15px 20px" }}
                >
                  The advanced gold label is aimed at companies and
                  organizations that need or want to meet a higher level of
                  security. It includes the 14 basic safety criteria plus 11
                  additional criteria. Fulfilling the requirements requires
                  appropriate preparation and a certain amount of effort. The
                  assessment is based on a validated self-declaration, which is
                  additionally confirmed by an external audit for the highest
                  security assurance. This process therefore takes a little more
                  time than for the other labels.
                </div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">
                  Label fee (initial)
                </div>
                <div className="pricelist__table--cell">950 €</div>
                <div className="pricelist__table--cell">1890 €</div>
                <div className="pricelist__table--cell">1990 €</div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">
                  Label fee (consecutive years)
                </div>
                <div className="pricelist__table--cell">900 €</div>
                <div className="pricelist__table--cell">1750 €</div>
                <div className="pricelist__table--cell">1850 €</div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">Audit fee</div>
                <div className="pricelist__table--cell">—</div>
                <div className="pricelist__table--cell">—</div>
                <div className="pricelist__table--cell">
                  According to audit partner
                </div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">Validity</div>
                <div className="pricelist__table--cell">1 Year</div>
                <div className="pricelist__table--cell">1 Year</div>
                <div className="pricelist__table--cell">1 Year</div>
              </div>
            </div>

            <div className="pricelist__table--ext">
              <div
                className="pricelist__table--row"
                style={{ marginTop: 20, marginBottom: 80 }}
              >
                <div className="pricelist__table--cell"></div>
                <div
                  className="pricelist__table--cell"
                  style={{
                    borderTop: "2px solid #1A55A2",
                    padding: 0,
                    margin: 0,
                    height: 50,
                    border: "none",
                  }}
                >
                  <a
                    style={{
                      border: "2px solid",
                      // padding: "5px",
                      width: "100%",
                      height: 50,
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    className="white-button-large"
                    href="/en/application-procedure-standard"
                  >
                    Your way to the standard label
                  </a>
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{
                    borderTop: "2px solid #1A55A2",
                    padding: 0,
                    margin: 0,
                    height: 50,
                    border: "none",
                  }}
                >
                  {" "}
                  <a
                    style={{
                      border: "2px solid",
                      // padding: "5px",
                      width: "100%",
                      height: 50,
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    className="white-button-large"
                    href="/en/application-procedure-silver"
                  >
                    Your way to the silver label
                  </a>
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{
                    borderTop: "2px solid #1A55A2",
                    padding: 0,
                    margin: 0,
                    height: 50,
                    border: "none",
                  }}
                >
                  {" "}
                  <a
                    style={{
                      border: "2px solid",
                      // padding: "5px",
                      width: "100%",
                      height: 50,
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    className="white-button-large"
                    href="/en/application-procedure-gold"
                  >
                    Your way to the gold label
                  </a>
                </div>
              </div>
            </div>

            <div className="pricelist__table--mobile">
              <div className="pricelist__table--mobile--container">
                <div className="pricelist__table--mobile--image--wrapper">
                  <img
                    className="mw-100"
                    src={BlueLabel}
                    alt="Cyber Trust Austria Standard Label"
                    title="Cyber Trust Austria Standard Label"
                  />
                </div>

                <div className="pricelist__table--mobile--title-wrapper">
                  Label
                </div>

                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label fee (initial)
                  </div>
                  <div className="pricelist__table--mobile--cell">950 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label fee (consecutive years)
                  </div>
                  <div className="pricelist__table--mobile--cell">900 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Audit fee
                  </div>
                  <div className="pricelist__table--mobile--cell">—</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">Validity</div>
                  <div className="pricelist__table--mobile--cell">1 Year</div>
                </div>
              </div>

              <div style={{ marginBottom: 50 }}>
                <a
                  style={{
                    // border: "2px solid",
                    // padding: "5px",
                    width: "100%",
                    height: 50,
                    textDecoration: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  className="white-button-large page__btn page__btn--blue-border"
                  href="/en/application-procedure-standard"
                  target="_blank"
                >
                  Your way to the standard label
                </a>
              </div>

              <div className="pricelist__table--mobile--container">
                <div className="pricelist__table--mobile--image--wrapper">
                  <img
                    className="mw-100"
                    src={SilverLabel}
                    alt="Cyber Trust Austria Silver Label"
                    title="Cyber Trust Austria Silver Label"
                  />
                </div>

                <div className="pricelist__table--mobile--title-wrapper">
                  Label Silver
                </div>

                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label fee (initial)
                  </div>
                  <div className="pricelist__table--mobile--cell">1890 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label fee (consecutive years)
                  </div>
                  <div className="pricelist__table--mobile--cell">1750 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Audit fee
                  </div>
                  <div className="pricelist__table--mobile--cell">—</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">Validity</div>
                  <div className="pricelist__table--mobile--cell">1 Year</div>
                </div>
              </div>

              <div style={{ marginBottom: 50 }}>
                <a
                  style={{
                    // border: "2px solid",
                    // padding: "5px",
                    width: "100%",
                    height: 50,
                    textDecoration: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  className="white-button-large page__btn page__btn--blue-border"
                  href="/en/application-procedure-silver"
                  target="_blank"
                >
                  Your way to the silver label
                </a>
              </div>

              <div className="pricelist__table--mobile--container">
                <div className="pricelist__table--mobile--image--wrapper">
                  <img
                    className="mw-100"
                    src={GoldLabel}
                    alt="Cyber Trust Austria Gold Label"
                    title="Cyber Trust Austria Gold Label"
                  />
                </div>

                <div className="pricelist__table--mobile--title-wrapper">
                  Label Gold
                </div>

                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label fee (initial)
                  </div>
                  <div className="pricelist__table--mobile--cell">1990 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label fee (consecutive years)
                  </div>
                  <div className="pricelist__table--mobile--cell">1850 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Audit fee
                  </div>
                  <div className="pricelist__table--mobile--cell">
                    According to audit partner
                  </div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">Validity</div>
                  <div className="pricelist__table--mobile--cell">1 Year</div>
                </div>
              </div>

              <div style={{ marginBottom: 50 }}>
                <a
                  style={{
                    // border: "2px solid",
                    // padding: "5px",
                    width: "100%",
                    height: 50,
                    textDecoration: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  className="white-button-large page__btn page__btn--blue-border"
                  href="/en/application-procedure-gold"
                  target="_blank"
                >
                  Your way to the gold label
                </a>
              </div>
            </div>

            {process.env.GATSBY_VARIANT === "Europe" ? (
              <>
                <h3 className="additional-services__title">Other Services</h3>
                <div className="additional-services__container">
                  <div className="additional-services__table">
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Rating repetition* within 6 months (Standard)</div>
                      <div className="additional-services__table--cell">€ 500</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Rating repetition* within 6 months (Silver/Gold)</div>
                      <div className="additional-services__table--cell">€ 950</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Later Upgrade from Standard to Silver</div>
                      <div className="additional-services__table--cell">€ 950</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Later Upgrade from Silver to Gold</div>
                      <div className="additional-services__table--cell">€ 300</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Additional issue of a Cyber Trust Austria label**</div>
                      <div className="additional-services__table--cell">€ 250</div>
                    </div>
                  </div>
                </div>
                <div className="additional-services__footnotes">
                  <p>* In the event of non-qualification in the first round</p>
                  <p>** Prerequisite: Achieved qualification for an equivalent Cyber Trust Europe label</p>
                </div>
                <div className="subscription-block">
                  <h3 className="subscription-block__title">Subscription</h3>
                  <p className="subscription-block__content">
                    If you subscribe to the label for three years, you get granted a 10% discount on the label fees.
                  </p>
                </div>
              </>
            ) : (
              <>
                <h3 className="additional-services__title">Other Services</h3>
                <div className="additional-services__container">
                  <div className="additional-services__table">
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Rating repetition* within 6 months (Standard)</div>
                      <div className="additional-services__table--cell">€ 500</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Rating repetition* within 6 months (Silver/Gold)</div>
                      <div className="additional-services__table--cell">€ 950</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Later Upgrade from Standard to Silver</div>
                      <div className="additional-services__table--cell">€ 950</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Later Upgrade from Silver to Gold</div>
                      <div className="additional-services__table--cell">€ 300</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Additional issue of a Cyber Trust Europe label**</div>
                      <div className="additional-services__table--cell">€ 250</div>
                    </div>
                    <div className="additional-services__table--row">
                      <div className="additional-services__table--cell">Additional German version of the Austria Label</div>
                      <div className="additional-services__table--cell">free</div>
                    </div>
                  </div>
                </div>
                <div className="additional-services__footnotes">
                  <p>* In the event of non-qualification in the first round</p>
                  <p>** Prerequisite: Achieved qualification for an equivalent Cyber Trust Austria label</p>
                </div>
                <div className="subscription-block">
                  <h3 className="subscription-block__title">Subscription</h3>
                  <p className="subscription-block__content">
                    If you subscribe to the label for three years, you get granted a 10% discount on the label fees.
                  </p>
                </div>
              </>
            )}

            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Not sure which label is right for your business? <br></br>
                    Let our experts advise you free of charge.
                  </b>
                </h2>
              </div>
              <div>
                <div>
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/free-advice"
                    target="_blank"
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default PriceList
