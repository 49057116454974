import React from "react"
import { APPLICATION_LABEL_API } from "../config/keys"
import { navigate } from "gatsby"

const FormConfirmation = ({
  visible,
  formData,
  goldLabel,
  labelType,
  closeDialog,
}) => {
  function getCountry(countryCode) {
    if (countryCode === "AF") return "Afghanistan"
    else if (countryCode === "AL") return "Albania"
    else if (countryCode === "DZ") return "Algeria"
    else if (countryCode === "AS") return "American Samoa"
    else if (countryCode === "AD") return "Andorra"
    else if (countryCode === "AO") return "Angola"
    else if (countryCode === "AI") return "Anguilla"
    else if (countryCode === "AQ") return "Antarctica"
    else if (countryCode === "AG") return "Antigua and Barbuda"
    else if (countryCode === "AR") return "Argentina"
    else if (countryCode === "AM") return "Armenia"
    else if (countryCode === "AW") return "Aruba"
    else if (countryCode === "AU") return "Australia"
    else if (countryCode === "AT") return "Austria"
    else if (countryCode === "BZ") return "Azerbaijan"
    else if (countryCode === "BS") return "Bahamas"
    else if (countryCode === "BH") return "Bahrain"
    else if (countryCode === "BD") return "Bangladesh"
    else if (countryCode === "BB") return "Barbados"
    else if (countryCode === "BY") return "Belarus"
    else if (countryCode === "BE") return "Belgium"
    else if (countryCode === "BZ") return "Belize"
    else if (countryCode === "BJ") return "Benin"
    else if (countryCode === "BM") return "Bermuda"
    else if (countryCode === "BT") return "Bhutan"
    else if (countryCode === "BO") return "Bolivia"
    else if (countryCode === "BA") return "Bosnia and Herzegovina"
    else if (countryCode === "BW") return "Botswana"
    else if (countryCode === "BR") return "Brazil"
    else if (countryCode === "IO") return "British Indian Ocean Territory"
    else if (countryCode === "VG") return "British Virgin Islands"
    else if (countryCode === "BN") return "Brunei"
    else if (countryCode === "BG") return "Bulgaria"
    else if (countryCode === "BF") return "Burkina Faso"
    else if (countryCode === "BI") return "Burundi"
    else if (countryCode === "KH") return "Cambodia"
    else if (countryCode === "CM") return "Cameroon"
    else if (countryCode === "CV") return "Cape Verde"
    else if (countryCode === "KY") return "Cayman Islands"
    else if (countryCode === "CF") return "Central African Republic"
    else if (countryCode === "TD") return "Chad"
    else if (countryCode === "CL") return "Chile"
    else if (countryCode === "CN") return "China"
    else if (countryCode === "CX") return "Christmas Island"
    else if (countryCode === "CC") return "Cocos Islands"
    else if (countryCode === "CO") return "Colombia"
    else if (countryCode === "KM") return "Comoros"
    else if (countryCode === "CK") return "Cook Islands"
    else if (countryCode === "CR") return "Costa Rica"
    else if (countryCode === "HR") return "Croatia"
    else if (countryCode === "CU") return "Cuba"
    else if (countryCode === "CW") return "Curacao"
    else if (countryCode === "CY") return "Cyprus"
    else if (countryCode === "CZ") return "Czech Republic"
    else if (countryCode === "CD") return "Democratic Republic of the Congo"
    else if (countryCode === "DK") return "Denmark"
    else if (countryCode === "DJ") return "Djibouti"
    else if (countryCode === "DM") return "Dominica"
    else if (countryCode === "DO") return "Dominican Republic"
    else if (countryCode === "TL") return "East Timor"
    else if (countryCode === "EC") return "Ecuador"
    else if (countryCode === "EG") return "Egypt"
    else if (countryCode === "SV") return "El Salvador"
    else if (countryCode === "GQ") return "Equatorial Guinea"
    else if (countryCode === "ER") return "Eritrea"
    else if (countryCode === "EE") return "Estonia"
    else if (countryCode === "ET") return "Ethiopia"
    else if (countryCode === "FK") return "Falkland Islands"
    else if (countryCode === "FO") return "Faroe Islands"
    else if (countryCode === "FJ") return "Fiji"
    else if (countryCode === "FI") return "Finland"
    else if (countryCode === "FR") return "France"
    else if (countryCode === "PF") return "French Polynesia"
    else if (countryCode === "GA") return "Gabon"
    else if (countryCode === "GM") return "Gambia"
    else if (countryCode === "GE") return "Georgia"
    else if (countryCode === "DE") return "Germany"
    else if (countryCode === "GH") return "Ghana"
    else if (countryCode === "GI") return "Gibraltar"
    else if (countryCode === "GR") return "Greece"
    else if (countryCode === "GL") return "Greenland"
    else if (countryCode === "GD") return "Grenada"
    else if (countryCode === "GU") return "Guam"
    else if (countryCode === "GT") return "Guatemala"
    else if (countryCode === "GG") return "Guernsey"
    else if (countryCode === "GN") return "Guinea"
    else if (countryCode === "GW") return "Guinea-Bissau"
    else if (countryCode === "GY") return "Guyana"
    else if (countryCode === "HT") return "Haiti"
    else if (countryCode === "HN") return "Honduras"
    else if (countryCode === "HK") return "Hong Kong"
    else if (countryCode === "HU") return "Hungary"
    else if (countryCode === "IS") return "Iceland"
    else if (countryCode === "IN") return "India"
    else if (countryCode === "ID") return "Indonesia"
    else if (countryCode === "IR") return "Iran"
    else if (countryCode === "IQ") return "Iraq"
    else if (countryCode === "IE") return "Ireland"
    else if (countryCode === "IM") return "Isle of Man"
    else if (countryCode === "IL") return "Israel"
    else if (countryCode === "IT") return "Italy"
    else if (countryCode === "CI") return "Ivory Coast"
    else if (countryCode === "JM") return "Jamaica"
    else if (countryCode === "JP") return "Japan"
    else if (countryCode === "JE") return "Jersey"
    else if (countryCode === "JO") return "Jordan"
    else if (countryCode === "KZ") return "Kazakhstan"
    else if (countryCode === "KE") return "Kenya"
    else if (countryCode === "KI") return "Kiribati"
    else if (countryCode === "XK") return "Kosovo"
    else if (countryCode === "KW") return "Kuwait"
    else if (countryCode === "KG") return "Kyrgyzstan"
    else if (countryCode === "LA") return "Laos"
    else if (countryCode === "LV") return "Latvia"
    else if (countryCode === "LB") return "Lebanon"
    else if (countryCode === "LS") return "Lesotho"
    else if (countryCode === "LR") return "Liberia"
    else if (countryCode === "LY") return "Libya"
    else if (countryCode === "LI") return "Liechtenstein"
    else if (countryCode === "LT") return "Lithuania"
    else if (countryCode === "LU") return "Luxembourg"
    else if (countryCode === "MO") return "Macau"
    else if (countryCode === "MK") return "Macedonia"
    else if (countryCode === "MG") return "Madagascar"
    else if (countryCode === "MW") return "Malawi"
    else if (countryCode === "MY") return "Malaysia"
    else if (countryCode === "MV") return "Maldives"
    else if (countryCode === "ML") return "Mali"
    else if (countryCode === "MT") return "Malta"
    else if (countryCode === "MH") return "Marshall Islands"
    else if (countryCode === "MR") return "Mauritania"
    else if (countryCode === "MU") return "Mauritius"
    else if (countryCode === "YT") return "Mayotte"
    else if (countryCode === "MX") return "Mexico"
    else if (countryCode === "FM") return "Micronesia"
    else if (countryCode === "MD") return "Moldova"
    else if (countryCode === "MC") return "Monaco"
    else if (countryCode === "MN") return "Mongolia"
    else if (countryCode === "ME") return "Montenegro"
    else if (countryCode === "MS") return "Montserrat"
    else if (countryCode === "MA") return "Morocco"
    else if (countryCode === "MZ") return "Mozambique"
    else if (countryCode === "MM") return "Myanmar"
    else if (countryCode === "NA") return "Namibia"
    else if (countryCode === "NR") return "Nauru"
    else if (countryCode === "NP") return "Nepal"
    else if (countryCode === "NL") return "Netherlands"
    else if (countryCode === "AN") return "Netherlands Antilles"
    else if (countryCode === "NC") return "New Caledonia"
    else if (countryCode === "NZ") return "New Zealand"
    else if (countryCode === "NI") return "Nicaragua"
    else if (countryCode === "NE") return "Niger"
    else if (countryCode === "NG") return "Nigeria"
    else if (countryCode === "NU") return "Niue"
    else if (countryCode === "KP") return "North Korea"
    else if (countryCode === "MP") return "Northern Mariana Islands"
    else if (countryCode === "NO") return "Norway"
    else if (countryCode === "OM") return "Oman"
    else if (countryCode === "PK") return "Pakistan"
    else if (countryCode === "PW") return "Palau"
    else if (countryCode === "PS") return "Palestine"
    else if (countryCode === "PA") return "Panama"
    else if (countryCode === "PG") return "Papua New Guinea"
    else if (countryCode === "PY") return "Paraguay"
    else if (countryCode === "PE") return "Peru"
    else if (countryCode === "PH") return "Philippines"
    else if (countryCode === "PN") return "Pitcairn"
    else if (countryCode === "PL") return "Poland"
    else if (countryCode === "PT") return "Portugal"
    else if (countryCode === "PR") return "Puerto Rico"
    else if (countryCode === "QA") return "Qatar"
    else if (countryCode === "CG") return "Republic of the Congo"
    else if (countryCode === "RE") return "Reunion"
    else if (countryCode === "RO") return "Romania"
    else if (countryCode === "RU") return "Russia"
    else if (countryCode === "RW") return "Rwanda"
    else if (countryCode === "BL") return "Saint Barthelemy"
    else if (countryCode === "SH") return "Saint Helena"
    else if (countryCode === "KN") return "Saint Kitts and Nevis"
    else if (countryCode === "LC") return "Saint Lucia"
    else if (countryCode === "MF") return "Saint Martin"
    else if (countryCode === "PM") return "Saint Pierre and Miquelon"
    else if (countryCode === "VC") return "Saint Vincent and the Grenadines"
    else if (countryCode === "WS") return "Samoa"
    else if (countryCode === "SM") return "San Marino"
    else if (countryCode === "ST") return "Sao Tome and Principe"
    else if (countryCode === "SA") return "Saudi Arabia"
    else if (countryCode === "SN") return "Senegal"
    else if (countryCode === "RS") return "Serbia"
    else if (countryCode === "SC") return "Seychelles"
    else if (countryCode === "SL") return "Sierra Leone"
    else if (countryCode === "SG") return "Singapore"
    else if (countryCode === "SX") return "Sint Maarten"
    else if (countryCode === "SK") return "Slovakia"
    else if (countryCode === "SI") return "Slovenia"
    else if (countryCode === "SB") return "Solomon Islands"
    else if (countryCode === "SO") return "Somalia"
    else if (countryCode === "ZA") return "South Africa"
    else if (countryCode === "KR") return "South Korea"
    else if (countryCode === "SS") return "South Sudan"
    else if (countryCode === "ES") return "Spain"
    else if (countryCode === "LK") return "Sri Lanka"
    else if (countryCode === "LD") return "Sudan"
    else if (countryCode === "SR") return "Suriname"
    else if (countryCode === "SJ") return "Svalbard and Jan Mayen"
    else if (countryCode === "SZ") return "Swaziland"
    else if (countryCode === "SE") return "Sweden"
    else if (countryCode === "CH") return "Switzerland"
    else if (countryCode === "SY") return "Syria"
    else if (countryCode === "TW") return "Taiwan"
    else if (countryCode === "TJ") return "Tajikistan"
    else if (countryCode === "TZ") return "Tanzania"
    else if (countryCode === "TH") return "Thailand"
    else if (countryCode === "TG") return "Togo"
    else if (countryCode === "TK") return "Tokelau"
    else if (countryCode === "TO") return "Tonga"
    else if (countryCode === "TT") return "Trinidad and Tobago"
    else if (countryCode === "TN") return "Tunisia"
    else if (countryCode === "TR") return "Turkey"
    else if (countryCode === "TM") return "Turkmenistan"
    else if (countryCode === "TC") return "Turks and Caicos Islands"
    else if (countryCode === "TV") return "Tuvalu"
    else if (countryCode === "VI") return "U.S. Virgin Islands"
    else if (countryCode === "UG") return "Uganda"
    else if (countryCode === "UA") return "Ukraine"
    else if (countryCode === "AE") return "United Arab Emirates"
    else if (countryCode === "GB") return "United Kingdom"
    else if (countryCode === "US") return "United States"
    else if (countryCode === "UY") return "Uruguay"
    else if (countryCode === "UZ") return "Uzbekistan"
    else if (countryCode === "VU") return "Vanuatu"
    else if (countryCode === "VA") return "Vatican"
    else if (countryCode === "VE") return "Venezuela"
    else if (countryCode === "VN") return "Vietnam"
    else if (countryCode === "WF") return "Wallis and Futuna"
    else if (countryCode === "EH") return "Western Sahara"
    else if (countryCode === "YE") return "Yemen"
    else if (countryCode === "ZM") return "Zambia"
    else if (countryCode === "ZW") return "Zimbabwe"
    else return ""
  }

  async function processPayments(formData) {
    const formBody = {
      language: "EN",
      variant: process.env.GATSBY_VARIANT,
      organization: {
        name: formData.organizationName,
        function: formData.organizationFunction,
        commercialRegisterNumber: formData.commercialRegisterNumber,
        vatIN: formData.vatIN,
      },
      address: {
        street: formData.street,
        houseNo: formData.houseNo,
        city: formData.city,
        country: {
          name: getCountry(formData.country),
          code: formData.country,
        },
        postalCode: formData.postalCode,
      },
      firstName: formData.firstNameOfTheApplicant,
      lastName: formData.lastNameOfTheApplicant,
      applicantEmail: formData.applicantEmail,
      invoiceEmail: formData.invoiceEmail,
      websiteDomain: formData.internetDomain,
      goldLabel: goldLabel,
      labelType: labelType,
      auditPartner: formData.auditPartner,
      subscription: formData.checkboxes.subscription,
    }

    await fetch(APPLICATION_LABEL_API, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formBody),
    })

    closeDialog()
    navigate("/en/payments")
  }

  function getTitle() {
    switch (labelType) {
      case "GOLD":
        return "Cyber Trust Gold Label"
      case "SILVER":
        return "Cyber Trust Silver Label"
      case "BLUE":
        return "Cyber Trust Label"
      default:
        return ""
    }
  }

  return (
    <div
      className="form-confirmation__container"
      style={{ display: visible ? "" : "none" }}
    >
      <div className="form-confirmation__dialog">
        <h2>Please check all details of your order</h2>
        <div className="form-details">
          <h4>Requested Label</h4>
          {formData.checkboxes.subscription && (
            <p>Subscription for three years</p>
          )}
          <p>{getTitle()}</p>

          <div className={labelType == "GOLD" ? "" : "hidden"}>
            <h4>Audit-Partner</h4>
            <p>{formData.auditPartner}</p>
          </div>

          <h4>Fees</h4>
          <p>
            Net fee{" "}
            {formData.checkboxes.subscription
              ? labelType == "GOLD"
                ? "5370"
                : labelType == "BLUE"
                  ? "2565"
                  : "5100"
              : labelType == "GOLD"
                ? "1990"
                : labelType == "BLUE"
                  ? "950"
                  : "1890"}{" "}
            EUR
            <br />
            Plus 20% VAT{" "}
            {formData.checkboxes.subscription
              ? labelType == "GOLD"
                ? "1074"
                : labelType == "BLUE"
                  ? "513"
                  : "1020"
              : labelType == "GOLD"
                ? "398"
                : labelType == "BLUE"
                  ? "190"
                  : "378"}{" "}
            EUR
            <br />
            Total payment{" "}
            {formData.checkboxes.subscription
              ? labelType == "GOLD"
                ? "6444"
                : labelType == "BLUE"
                  ? "3078"
                  : "6120"
              : labelType == "GOLD"
                ? "2388"
                : labelType == "BLUE"
                  ? "1140"
                  : "2268"}{" "}
            EUR
          </p>

          <h4>Organisation details</h4>
          <p>
            Name: {formData.organizationName}
            <br />
            Street: {formData.street}
            <br />
            Number: {formData.houseNo}
            <br />
            ZIP Code: {formData.postalCode}
            <br />
            City: {formData.city}
            <br />
            Country: {formData.country}
            <br />
            Corporate register number: {formData.commercialRegisterNumber}
            <br />
            UID-Number (VAT-Number): {formData.vatIN}
            <br />
            Internet domain(s): {formData.internetDomain}
          </p>

          <h4>Contact</h4>
          <p>
            First name: {formData.firstNameOfTheApplicant}
            <br />
            Family name: {formData.lastNameOfTheApplicant}
            <br />
            Function: {formData.organizationFunction}
            <br />
            Invoice E-Mail: {formData.invoiceEmail}
            <br />
            Applicant E-Mail: {formData.applicantEmail}
          </p>
        </div>
        <div className="form-confirmation__buttons">
          <button
            className="page__btn form-confirmation__back-button"
            onClick={closeDialog}
          >
            Back
          </button>
          &nbsp;
          <button
            className="page__btn page__btn--blue form-confirmation__confirm-button"
            onClick={() => processPayments(formData)}
          >
            Confirm order
          </button>
        </div>
      </div>
    </div>
  )
}

export default FormConfirmation
