import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './Carousel.css';

const Carousel = () => {
    const data = useStaticQuery(graphql`
    query {
      allDatoCmsAsset(
        filter: { tags: { in: ["customerlogo"] } }
      ) {
        nodes {
          url
        }
      }
    }
  `);

    const images = data.allDatoCmsAsset.nodes;
    const duplicatedImages = [...images, ...images]; // Duplicate images for seamless loop

    return (
        <div className="carousel-container">
            <div className="carousel-track">
                {duplicatedImages.map((image, index) => (
                    <div className="image-wrapper" key={index}>
                        <img
                            src={image.url}
                            alt={image.alt || 'Partner logo'}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel; 